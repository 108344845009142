<template>
  <div class="h-100">
    <k-header-card class="d-flex justify-content-between">
      <div
        class="w-50 d-flex justify-content-center align-items-start flex-column"
      >
        <span
          class="text-primary d-flex align-items-center cursor-pointer"
          @click="routeToPreviousPage"
        >
          <feather-icon
            class="me-1"
            icon="ChevronLeftIcon"
          /> Back To {{ previousRouteText }}
        </span>
        <span
          v-if="order"
          class="date-label"
        >
          Order Id: {{ order.orderSeqId || order.orderId }}
        </span>
      </div>
      <div class="w-25 d-flex justify-content-end align-items-center">
        <b-link
          class="k-btn k-btn-outline-info text-decoration-none px-5"
          :to="{ path : `/order-management/${orderId}/order-products-tab` }"
        >
          EDIT
        </b-link>
      </div>
    </k-header-card>
    <div class="order-details">
      <div class="width-medium">
        <order-summary :order-data="order" />
      </div>
      <div class="width-medium">
        <shipment-summary
          :shipment-data="shipments"
          :loading="loadingShipments"
        />
        <notes
          class="mt-3"
          :order-id="orderId"
          :notes="order ? order.notes : ''"
        />
      </div>
      <div class="width-medium">
        <transction-summary
          :transaction-data="transactions"
          :loading="loadingTransactions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import { apiToastError } from '@/@core/utils/toast'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { ROUTES } from '@/constants'
import { FETCH_ORDER_BY_ID } from '@/store/modules/order.module'
import { FETCH_TRANSACTIONS } from '@/store/modules/transaction-module'
import { BLink } from 'bootstrap-vue'
import Notes from './notes.vue'
import orderSummary from './orderSummary.vue'
import ShipmentSummary from './shipmentSummary.vue'
import TransctionSummary from './transactionSummary.vue'

export default {
  components: {
    orderSummary,
    FeatherIcon,
    ShipmentSummary,
    TransctionSummary,
    Notes,
    KHeaderCard,
    BLink,
  },
  data() {
    return {
      orderId: this.$route.params.orderId, // Fetch from param and send Id to order, shipment , transaction for getting details
      order: null,
      shipments: null,
      loadingShipments: true,
      transactions: null,
      loadingTransactions: true,
      previousRouteText: 'Orders',
      prevRoute: null,
    }
  },
  created() {
    this.loadOrderSummaryDetails()
    this.loadShipments()
    this.loadTransactions()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
      vm.getPreviousRoute(from)
    })
  },
  methods: {
    loadOrderSummaryDetails() {
      this.$store.dispatch(FETCH_ORDER_BY_ID, this.orderId)
        .then(res => {
          this.order = res
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    getPreviousRoute() {
      if ([ROUTES.SHIPMENTS.name].includes(this.prevRoute?.name)) {
        this.previousRouteText = 'Shipments'
      }
      else {
        this.previousRouteText = 'Orders'
      }
    },
    routeToPreviousPage() {
      if (this.prevRoute?.name) {
        this.$router.push({ name: this.prevRoute?.name })
      }
      else {
        this.$router.push({ name: ROUTES.ORDERS.CHILDREN.LIST.name })
      }
    },
    loadShipments() {
      this.loadingShipments = true
      this.$kpRequest({
        ...this.$kpEndpoint.shipment.getShipments,
        params: { orderId: this.orderId },
      })
        .then(res => {
          this.shipments = res.data.data.shipments
          this.loadingShipments = false
        })
        .catch(() => {
          this.loadingShipments = false
        })
    },
    loadTransactions() {
      this.loadingTransactions = true
      this.$store
        .dispatch(FETCH_TRANSACTIONS, { orderId: this.orderId })
        .then(res => {
          this.transactions = res.data.data.data
          this.loadingTransactions = false
        })
        .catch(() => {
          this.loadingTransactions = false
        })
    },
  },
}
</script>
