<template>
  <div
    v-if="shipment"
    class="summary-body"
  >
    <div class="mb-2">
      <p class="content">
        Shipment ID:
      </p>
      <p>{{ shipment.shipmentSeqId || shipment._id }}</p>
    </div>
    <div class="mb-2">
      <p class="content">
        Shipment Status:
      </p>
      <p>{{ shipment.status }}</p>
    </div>
    <div
      v-if="shipment.shippedDate"
      class="mb-2"
    >
      <p class="content">
        Shipped Date:
      </p>
      <p>{{ formattedDate(shipment.shippedDate) }}</p>
    </div>
    <div
      v-if="shipment.deliveredDate"
      class="mb-2"
    >
      <p class="content">
        Delivered Date:
      </p>
      <p>{{ formattedDate(shipment.deliveredDate) }}</p>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '@/@core/utils/format'

export default {
  name: 'ShipmentSummaryDetails',
  props: {
    shipment: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      formattedDate,
    }
  },
}
</script>
