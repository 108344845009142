<template>
  <div class="w-100">
    <k-card class="custom-card">
      <div
        v-if="loading"
        class="spinner-block"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="k-card-body shipment-summary-card"
      >
        <template>
          <div class="summary-heading">
            <span> Shipping Summary </span>
            <b-link class="navigate-button show-link">
              Details
              <b-icon
                icon="box-arrow-up-right"
                class="icon-size"
              />
            </b-link>
            <span
              v-b-tooltip.hover.bottom
              :title="collapseAll ? 'Collapse All' : 'Expand All'"
              class="collpase-all-icon"
              :class="collapseAll ? 'down' : ''"
              @click="collapseAll = !collapseAll"
            />
          </div>
          <div v-if="shipmentsLocal.length">
            <div
              v-for="(shipment, index) in shipmentsLocal"
              :key="shipment._id"
            >
              <div class="summary-sub-heading">
                <span
                  @click="
                    shipment.isShipmentCollapse = !shipment.isShipmentCollapse
                  "
                >
                  <span
                    class="collapse-icon"
                    :class="shipment.isShipmentCollapse ? 'down' : ''"
                  />
                  Shipment {{ index + 1 }}
                  <span class="date-label">
                    (created: {{ formattedDate(shipment.createdAt) }})
                  </span>
                </span>
              </div>
              <b-collapse
                :id="shipment._id"
                :visible="shipment.isShipmentCollapse"
              >
                <shipment-summary-details
                  :shipment="shipment"
                />
              </b-collapse>
            </div>
          </div>
          <div
            v-else
            class="text-center p-5 w-100 font-weight-bold"
          >
            No Shipments Yet
          </div>
        </template>
      </div>
    </k-card>
    <!-- <k-card class="custom-card notes-card-margin">
      <div class="k-card-body notes-card">
        <div class="summary-heading pe-0">
          <span> Notes </span>
          <b-link
            class="navigate-button"
            @click="addOrEditNotes"
          >
            Add a Note
            <b-icon
              icon="box-arrow-up-right"
              class="icon-size"
            />
          </b-link>
        </div>
      </div>
    </k-card> -->
  </div>
</template>

<script>
import {
  VBTooltip, BCollapse, BSpinner, BLink, BIcon,
} from 'bootstrap-vue'
import { KCard } from '@kingpin-global/kingpin-ui'
import {
  SET_COMPONENT,
  SET_FORM_ACTIONS,
  SET_SUB_TITLE,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { formattedDate } from '@/@core/utils/format'
import AddNotes from './addNotes.vue'
import ShipmentSummaryDetails from './shipmentSummaryDetails.vue'

export default {
  components: {
    BCollapse,
    BSpinner,
    KCard,
    BLink,
    BIcon,
    ShipmentSummaryDetails,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    shipmentData: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      order: null,
      shipmentsLocal: [],
      collapseAll: false,
      showNotesSideBar: false,
      formattedDate,
    }
  },
  computed: {
    addNotesComponent() {
      return AddNotes
    },
  },
  watch: {
    collapseAll() {
      if (this.collapseAll) {
        this.shipmentsLocal.forEach(shipment => {
          shipment.isShipmentCollapse = true
        })
      } else {
        this.shipmentsLocal.forEach(shipment => {
          shipment.isShipmentCollapse = false
        })
      }
    },
    shipmentData() {
      this.shipmentsLocal = this.shipmentData.map((shipment, i) => ({
        ...shipment,
        isShipmentCollapse: i === this.shipmentData.length - 1,
      }))
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },

    addOrEditNotes() {
      this.$store.commit(SET_TITLE, 'Add a Note')
      this.$store.commit(SET_SUB_TITLE, 'Notes by Rabie')
      this.$store.commit(SET_COMPONENT, this.addNotesComponent)
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    formActions(actionType) {
      switch (actionType) {
        case 'submit': {
          this.toggleSidebar()
          break
        }
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        default:
          this.toggleSidebar()
      }
    },
  },
}
</script>
