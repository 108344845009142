<template>
  <div class="sidebar-create-padding">
    <div class="form-block mt-3">
      <div class="input-block">
        <span class="mb-2">
          Order ID
        </span>
        <k-form-input
          v-model="orderID"
          type="text"
          placeholder="Order Id"
          class="form-input-text"
          disabled
        />
      </div>
      <div class="input-block">
        <span class="mb-2">
          Date Created
        </span>
        <k-form-input
          v-model="date"
          type="text"
          placeholder="Order Id"
          class="form-input-text"
          disabled
        />
      </div>
      <div class="input-block">
        <span class="mb-2"> Notify Admins </span>
        <b-dropdown
          class="w-100 k-search-dropdown dropdown-custom-class"
          variant="none"
          toggle-class="drop-caret-icon"
          :text="selectedAdminUser.id ?
            `${selectedAdminUser.name} (${selectedAdminUser.email})` : 'Select an Admin'"
        >
          <b-dropdown-form>
            <k-form-input
              v-model="searchAdmin"
              type="text"
              placeholder="Search for an admin"
            />
            <feather-icon
              icon="SearchIcon"
              class="drop-search-icon"
              size="22"
            />
          </b-dropdown-form>
          <template v-if="filteredAdminUsers.length">
            <b-dropdown-item
              v-for="adminUser in filteredAdminUsers"
              :key="adminUser._id"
              :active="selectedAdminUser.id === adminUser._id"
              @click="setAdminUser(adminUser)"
            >
              {{ adminUser.name }} ({{ adminUser.email }})
            </b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item disabled>
              No matching fields
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <div class="input-block">
        <span class="mb-2">
          Note
        </span>
        <b-form-textarea
          type="text"
          placeholder="Add a Note.."
          class="form-input-text"
          rows="4"
        />
      </div>
    </div>
    <div class="d-flex align-items-center mt-1">
      <k-button
        class="me-4"
        variant="info"
        block
      >
        <!-- As of now closing for submit later write a function for submit -->
        SAVE CHANGES
      </k-button>
      <k-button
        variant="outline-info"
        block
        @click="closeSidebar"
      >
        CANCEL
      </k-button>
    </div>
  </div>
</template>

<script>
import { KButton, KFormInput } from '@kingpin-global/kingpin-ui'
import {
  BDropdown, BDropdownForm, BDropdownItem, BFormTextarea,
} from 'bootstrap-vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { FETCH_ADMIN_USERS } from '@/store/modules/admin-user.module'

export default {
  components: {
    KFormInput,
    BDropdown,
    BDropdownForm,
    BDropdownItem,
    BFormTextarea,
    FeatherIcon,
    KButton,
  },
  data() {
    return {
      searchAdmin: '',
      orderID: '12ehsjdfaksdfhakj',
      date: '29/02/2022',
      adminUsers: null,
      filteredAdminUsers: null,
      selectedAdminUser: {
        name: '',
        id: '',
        email: '',
      },
    }
  },
  watch: {
    searchAdmin() {
      this.filteredAdminUsers = this.adminUsers.filter(adminUser => adminUser.name.toLowerCase()
        .includes(this.searchAdmin.toLowerCase())
        || adminUser.email.includes(this.searchAdmin.toLowerCase()))
    },
  },
  created() {
    this.getAdminUsers()
  },
  methods: {
    async getAdminUsers() {
      this.adminUsers = this.$store.getters.adminUsers
      this.filteredAdminUsers = this.$store.getters.adminUsers
      if (this.adminUsers.length === 0) {
        await this.$store.dispatch(FETCH_ADMIN_USERS)
        this.adminUsers = this.$store.getters.adminUsers
        this.filteredAdminUsers = this.$store.getters.adminUsers
      }
    },
    setAdminUser(adminUser) {
      this.selectedAdminUser.id = adminUser._id
      this.selectedAdminUser.name = adminUser.name
      this.selectedAdminUser.email = adminUser.email
    },
    closeSidebar() {
      this.$emit('dynamic-emits', 'cancel')
    },
  },
}
</script>
