<template>
  <div class="w-100">
    <k-card class="custom-card">
      <div
        class="k-card-body notes-summary-card"
      >
        <template>
          <div class="notes-heading">
            <span> Notes </span>
          </div>
          <div>
            <b-form-textarea
              type="text"
              placeholder="Add a Note.."
              class="form-input-text notes-text-area"
              rows="6"
              :value="notes"
              @blur="updateNotes($event)"
            />
          </div>
        </template>
      </div>
    </k-card>
  </div>
</template>

<script>
import { apiToastErrorV2 } from '@/@core/utils/toast'
import { KCard } from '@kingpin-global/kingpin-ui'
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    KCard,
  },
  props: {
    orderId: {
      type: String,
      default() {
        return ''
      },
    },
    notes: {
      type: String,
      default() {
        return ''
      },
    },
  },
  methods: {
    updateNotes(e) {
      this.$kpRequest({
        ...this.$kpParams(this.$kpEndpoint.orders.patch, this.orderId),
        payload: { notes: e.target.value },
      })
        .catch(err => {
          apiToastErrorV2(err)
        })
    },
  },
}
</script>
