<template>
  <div class="w-100">
    <k-card class="custom-card">
      <div
        v-if="!orderData"
        class="spinner-block"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="k-card-body order-summary-card"
      >
        <div class="summary-heading">
          <span> Order Summary </span>
          <span
            v-b-tooltip.hover.bottom
            :title="collapseAll ? 'Collapse All' : 'Expand All'"
            class="collpase-all-icon"
            :class="collapseAll ? 'down' : ''"
            @click="collapseAll = !collapseAll"
          />
        </div>
        <div class="summary-sub-heading">
          <span @click="orderCollapse = !orderCollapse">
            <span
              class="collapse-icon"
              :class="orderCollapse ? 'down' : ''"
            />
            Order Summary</span>
        </div>
        <b-collapse
          id="order-summary"
          :visible="orderCollapse"
        >
          <div class="summary-body text-start">
            <div class="mb-2">
              <p class="content">
                Order Status:
              </p>
              <p class="text-capitalize">
                {{ orderData.status }}
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Brand Name:
              </p>
              <p class="text-capitalize">
                {{ orderData.brandName }}
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Retailer Name:
              </p>
              <p class="text-capitalize">
                {{ orderData.retailerName }}
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Collection Name:
              </p>
              <p class="text-capitalize">
                {{ orderData.collectionName }}
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Amount:
              </p>
              <p>
                {{ formatCurrency(orderData.totalPrice, orderData.currency) }}
              </p>
            </div>
            <template v-if="orderData.discountInCredit">
              <div class="mb-2">
                <p class="content">
                  Credit points applied:
                </p>
                <p>
                  {{ formatCurrency(orderData.discountInCredit, orderData.currency) }}
                </p>
              </div>
              <div class="mb-2">
                <p class="content">
                  Amount after discount:
                </p>
                <p>
                  {{ formatCurrency(orderData.totalPriceAfterDiscount, orderData.currency) }}
                </p>
              </div>
            </template>
          </div>
        </b-collapse>
        <div class="summary-sub-heading">
          <span @click="commercialCollapse = !commercialCollapse">
            <span
              class="collapse-icon"
              :class="commercialCollapse ? 'down' : ''"
            />
            Terms</span>
        </div>
        <b-collapse
          id="commercial-terms"
          :visible="commercialCollapse"
        >
          <div class="summary-body">
            <div class="mb-2">
              <p class="content">
                Vat Percentage - Retailer
              </p>
              <p>{{ orderData.isVatEligible ? getValueWithSymbol(orderData.vatPercentage, '%') : 'VAT is not enabled for the order for retailer' }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Vat Percentage - Brand
              </p>
              <p>{{ orderData.isVatEligibleForBrands ? getValueWithSymbol(orderData.vatPercentageForBrands, '%') : 'VAT is not enabled for the order for brand' }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Brand's Commercial terms:
              </p>
              <p>{{ getValueWithSymbol(orderData.commercialTerms, '%') }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Retailer's Payment terms:
              </p>
              <p>{{ orderData.paymentTerms }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Brand's Payment terms:
              </p>
              <p>{{ orderData.paymentTermsForBrand }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Retailer's Logistics terms:
              </p>
              <p>{{ orderData.logisticsTerms }}</p>
            </div>
            <div class="mb-2">
              <p class="content">
                Price Multiplier
              </p>
              <p>{{ orderData.discount }}</p>
            </div>
          </div>
        </b-collapse>
        <div class="summary-sub-heading">
          <span @click="addressCollapse = !addressCollapse">
            <span
              class="collapse-icon"
              :class="addressCollapse ? 'down' : ''"
            />
            Address</span>
        </div>
        <b-collapse
          id="address"
          :visible="addressCollapse"
        >
          <div class="summary-body">
            <div class="mb-2">
              <p class="content">
                Brand's Billing Address:
              </p>
              <p v-if="!!orderData.billingAddressForBrand">
                <span>{{ orderData.billingAddressForBrand.billingContactName }},
                </span>
                <span>{{ orderData.billingAddressForBrand.billingContactNumber }},
                </span>
                <span>{{ orderData.billingAddressForBrand.billingAddressLine1 }},
                </span>
                <span
                  v-if="orderData.billingAddressForBrand.billingAddressLine2"
                >{{ orderData.billingAddressForBrand.billingAddressLine2 }},
                </span>
                <span>{{ orderData.billingAddressForBrand.billingCity }},
                </span>
                <span>{{
                  orderData.billingAddressForBrand.billingCountry
                }}</span>
                <span>
                  - {{ orderData.billingAddressForBrand.billingZipcode }}</span>
              </p>
              <p v-else>
                ---
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Retailer's Billing Address:
              </p>
              <p v-if="!!orderData.billingAddressForRetailer">
                <span>{{ orderData.billingAddressForRetailer.billingContactName }},
                </span>
                <span>{{
                  orderData.billingAddressForRetailer.billingContactNumber
                }},
                </span>
                <span>{{
                  orderData.billingAddressForRetailer.billingAddressLine1
                }},
                </span>
                <span
                  v-if="orderData.billingAddressForRetailer.billingAddressLine2"
                >{{
                  orderData.billingAddressForRetailer.billingAddressLine2
                }},
                </span>
                <span>{{ orderData.billingAddressForRetailer.billingCity }},
                </span>
                <span>{{
                  orderData.billingAddressForRetailer.billingCountry
                }}</span>
                <span>
                  -
                  {{ orderData.billingAddressForRetailer.billingZipcode }}</span>
              </p>
              <p v-else>
                ---
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Brand's Shipping Address:
              </p>
              <p v-if="!!orderData.shippingAddressForBrand">
                <span>{{ orderData.shippingAddressForBrand.shippingContactName }},
                </span>
                <span>{{
                  orderData.shippingAddressForBrand.shippingContactNumber
                }},
                </span>
                <span>{{ orderData.shippingAddressForBrand.shippingAddressLine1 }},
                </span>
                <span
                  v-if="orderData.shippingAddressForBrand.shippingAddressLine2"
                >{{ orderData.shippingAddressForBrand.shippingAddressLine2 }},
                </span>
                <span>{{ orderData.shippingAddressForBrand.shippingCity }},
                </span>
                <span>{{
                  orderData.shippingAddressForBrand.shippingCountry
                }}</span>
                <span>
                  -
                  {{ orderData.shippingAddressForBrand.shippingZipcode }}</span>
              </p>
              <p v-else>
                ---
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Retailer's Shipping Address:
              </p>
              <p v-if="!!orderData.shippingAddressForRetailer">
                <span>{{
                  orderData.shippingAddressForRetailer.shippingContactName
                }},
                </span>
                <span>{{
                  orderData.shippingAddressForRetailer.shippingContactNumber
                }},
                </span>
                <span>{{
                  orderData.shippingAddressForRetailer.shippingAddressLine1
                }},
                </span>
                <span
                  v-if="
                    orderData.shippingAddressForRetailer.shippingAddressLine2
                  "
                >{{
                  orderData.shippingAddressForRetailer.shippingAddressLine2
                }},
                </span>
                <span>{{ orderData.shippingAddressForRetailer.shippingCity }},
                </span>
                <span>{{
                  orderData.shippingAddressForRetailer.shippingCountry
                }}</span>
                <span>
                  -
                  {{
                    orderData.shippingAddressForRetailer.shippingZipcode
                  }}</span>
              </p>
              <p v-else>
                ---
              </p>
            </div>
          </div>
        </b-collapse>
        <div class="summary-sub-heading">
          <span @click="availabilityCollapse = !availabilityCollapse">
            <span
              class="collapse-icon"
              :class="availabilityCollapse ? 'down' : ''"
            />
            Availability Dates</span>
        </div>
        <b-collapse
          id="availabilty-dates"
          :visible="availabilityCollapse"
        >
          <div class="summary-body">
            <div class="mb-2">
              <p class="content">
                Requested Delivery Window
              </p>
              <p>
                {{
                  orderData.requestedDeliveryDate
                    ? getMonthAndYearFromDate(orderData.requestedDeliveryDate)
                    : "--/--/----"
                }}
              </p>
            </div>
            <div class="mb-2">
              <p class="content">
                Estimated Delivery Window
              </p>
              <p>
                {{
                  orderData.expectedAvailabilityDate
                    ? getMonthAndYearFromDate(orderData.expectedAvailabilityDate)
                    : "--/--/----"
                }}
              </p>
            </div>
          </div>
        </b-collapse>
      </div>
    </k-card>
  </div>
</template>

<script>
import {
  VBTooltip, BCollapse, BSpinner,
} from 'bootstrap-vue'
import { KCard } from '@kingpin-global/kingpin-ui'
import { formattedDate, getValueWithSymbol } from '@/@core/utils/format'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formatCurrency, getMonthAndYearFromDate } = utils

export default {
  components: {
    BCollapse,
    BSpinner,
    KCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    orderData: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      orderCollapse: true,
      commercialCollapse: false,
      addressCollapse: false,
      availabilityCollapse: false,
      collapseAll: false,
      formattedDate,
      getValueWithSymbol,
      formatCurrency,
      getMonthAndYearFromDate,
    }
  },
  watch: {
    collapseAll() {
      if (this.collapseAll) {
        // Making All collapse visbile if collapseAll toggled
        this.orderCollapse = true
        this.commercialCollapse = true
        this.addressCollapse = true
        this.availabilityCollapse = true
      } else {
        // Making All collapse invisbile if collapseAll toggled
        this.orderCollapse = false
        this.commercialCollapse = false
        this.addressCollapse = false
        this.availabilityCollapse = false
      }
    },
  },
}
</script>
