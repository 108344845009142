<template>
  <div class="w-100">
    <k-card class="custom-card">
      <div
        v-if="loading"
        class="spinner-block"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="k-card-body transction-summary-card"
      >
        <template>
          <div class="summary-heading">
            <span> Transaction Summary </span>
            <b-link class="navigate-button show-link">
              Details
              <b-icon
                icon="box-arrow-up-right"
                class="icon-size"
              />
            </b-link>
            <span
              v-b-tooltip.hover.bottom
              :title="collapseAll ? 'Collapse All' : 'Expand All'"
              class="collpase-all-icon"
              :class="collapseAll ? 'down' : ''"
              @click="collapseAll = !collapseAll"
            />
          </div>
          <div v-if="transactionsLocal.length">
            <div
              v-for="(transaction, index) in transactionsLocal"
              :key="transaction._id"
            >
              <div class="summary-sub-heading">
                <span
                  @click="
                    transaction.istransactionCollapse =
                      !transaction.istransactionCollapse
                  "
                >
                  <span
                    class="collapse-icon"
                    :class="transaction.istransactionCollapse ? 'down' : ''"
                  />
                  Transaction {{ index + 1 }}
                  <span class="date-label">
                    (created: {{ formattedDate(transaction.createdAt) }})
                  </span>
                </span>
              </div>
              <b-collapse
                id="transaction1"
                :visible="transaction.istransactionCollapse"
              >
                <transaction-summary-details :transaction="transaction" />
              </b-collapse>
            </div>
          </div>
          <div
            v-else
            class="text-center p-5 w-100 font-weight-bold"
          >
            No Transactions Yet
          </div>
        </template>
      </div>
    </k-card>
  </div>
</template>

<script>
import {
  VBTooltip, BCollapse, BSpinner, BLink, BIcon,
} from 'bootstrap-vue'
import { KCard } from '@kingpin-global/kingpin-ui'
import { formattedDate } from '@/@core/utils/format'
import TransactionSummaryDetails from './transactionSummaryDetails.vue'

export default {
  components: {
    BCollapse,
    BSpinner,
    KCard,
    BIcon,
    BLink,
    TransactionSummaryDetails,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    transactionData: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      order: null,
      transactionsLocal: [],
      collapseAll: false,
      notes1Collapse: false,
      notes2Collapse: false,
      formattedDate,
    }
  },
  watch: {
    collapseAll() {
      if (this.collapseAll) {
        this.transactionsLocal.forEach(transaction => {
          transaction.istransactionCollapse = true
        })
      } else {
        this.transactionsLocal.forEach(transaction => {
          transaction.istransactionCollapse = false
        })
      }
    },
    transactionData() {
      this.transactionsLocal = this.transactionData.map((transaction, i) => ({
        ...transaction,
        istransactionCollapse: i === 0,
      }))
      this.transactionsLocal.reverse()
    },
  },
}
</script>
