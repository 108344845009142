<template>
  <div
    v-if="transaction"
    class="summary-body"
  >
    <div class="mb-2">
      <p class="content">
        Transaction ID:
      </p>
      <p>{{ transaction._id }}</p>
    </div>
    <div class="mb-2">
      <p class="content">
        Due Date:
      </p>
      <p>{{ formattedDate(transaction.dueDate) }}</p>
    </div>
    <div class="mb-2">
      <p class="content">
        Amount Due:
      </p>
      <p>{{ formatCurrency(transaction.amountDue, transaction.currency) || '--' }}</p>
    </div>
    <div class="mb-2">
      <p class="content">
        Amount Paid:
      </p>
      <p>{{ formatCurrency(transaction.amountPaid || 0, transaction.currency) || '--' }}</p>
    </div>
    <div class="mb-2">
      <p class="content">
        Type:
      </p>
      <p class="text-capitalize">
        {{ TRANSACTION_TYPES[TRANSACTION_ROLE[transaction.role]] }}
      </p>
    </div>
    <div class="mb-2">
      <p class="content">
        Status:
      </p>
      <p>{{ TRANSACTION_STATUS[transaction.status] }}</p>
    </div>
    <div
      v-if="transaction.reference"
      class="mb-2"
    >
      <p class="content">
        Notes:
      </p>
      <p>{{ transaction.reference }}</p>
    </div>
  </div>
</template>

<script>
import { formattedDate, getValueWithSymbol } from '@/@core/utils/format'
import {
  TRANSACTION_ROLE, TRANSACTION_TYPES, TRANSACTION_STATUS,
} from '@/constants'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formatCurrency } = utils

export default {
  name: 'TransactionSummaryDetails',
  props: {
    transaction: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      TRANSACTION_ROLE,
      TRANSACTION_TYPES,
      TRANSACTION_STATUS,
      formattedDate,
      getValueWithSymbol,
      formatCurrency,
    }
  },
}
</script>
